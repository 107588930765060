import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, Button } from 'reactstrap';
import { Loading } from './LoadingComponent';
import { FadeTransform } from 'react-animation-components';
import CarouselComp from './CarouselComponent';
import ScrollAnimation from 'react-animate-on-scroll';
import Typewriter from "typewriter-effect";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade, Stagger } from 'react-animation-components';
import { Media } from "reactstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

AOS.init();
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

function Type() {
    return (
      <h1 className="text-center typed-text">
        <Typewriter
    
         onInit={(typewriter)=> {
    
         typewriter
         .start()
         .typeString("Ma'ashiyaat Economics Network")
           
         .pauseFor(1000)
         .deleteAll()
         .typeString("Welcomes You")
         .pauseFor(1000)
         .deleteAll()
         .typeString("Ma'ashiyaat Economics Network")
         .start();
         }}
         />
      </h1>
    );
}

function RenderCards( {blog} ) {
    if (blog.featured) {
        return(
            <div className="cards">
                <Link to={`/research/${blog._id}`} style={{ textDecoration: 'none' }} >

                <img src={ blog.image } alt={ '' } />
                <div className="card-content">
                    <h2 className="blog-text">{ blog.Title }</h2>
                    {/* <p className="blog-text">- { blog.author }</p> */}
                 </div>
                 </Link>
            </div>
        );
    }
    else{
        return(
            <div>
            </div>
        )
    }
}

const Cards = (props) => {
    const blogs = props.blogs.blogs.map((blog) => {
        return (
            <Fade in key={blog._id}>
                <div data-aos="fade-up-right" data-aos-duration="1100">                    
                    <RenderCards blog={blog} />
                </div>
            </Fade>
        );
    }
    );
    // </div>

    
        return (
            <Media list>
                <Stagger in>
                    
            <div className="cards-container">
                
                    {blogs}
                    </div>
                </Stagger>
            </Media>
        );
    
}

function FeaturedBlogs(props) {
    return(
        <div className="">
            <br />
            <h1 className='featured-heading' style={{ 'text-align': 'center' }}>
                Featured Articles
            </h1>
            
            <Cards blogs={props.blogs} />
        
          </div> 
    )
    
}


// eslint-disable-next-line 
// const Promo = (props) => {

//     const blog = props.blogs.blogs.map((blog) => {
//         return (
//             <Fade in key={blog._id}>
//                 <div className="">
//                     <SampleBlog blog={blog} />
//                 </div>
//             </Fade>
//         );
//     });

//     if (props.blogs.isLoading) {
//         return(
//                 <Loading />
//         );
//     }
//     else if (props.blogs.errMess) {
//         return(
//             <div className="col-12"> 
//                 <h4>{props.blogs.errMess}</h4>
//             </div>
//         );
//     }
//     else {
//         return (
//             <Media list>
//                 <Stagger in>
//                     {blog}
//                 </Stagger>
//             </Media>
//         );
//         }
//     }

// //     }
// //   }

// 


// function SampleBlog({item}) {
//     let settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         cssEase: "linear"
//     }
//     return (
//         <Slider {...settings}>
//             <div className="sample-wrapper">
//                 <div className="sample-card">
//                     <div className="card-image">
//                         <img src={item.image} />
//                     </div>
//                     <ul className="social-icons">
//                         <li><a href="#"><i className="fa fa-facebook"></i></a></li>
//                         <li><a href="#"><i className="fa fa-instagram"></i></a></li>
//                         <li><a href="#"><i className="fa fa-twitter"></i></a></li>
//                         <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
//                     </ul>
//                     <div className="details">
//                         <h2>{item.Title}<span className="job-title">UI Developer</span></h2>
//                     </div>
//                 </div>
//             </div>
//         </Slider>
    
//     )
// }


// function Cards({item}) {

//     const alumni = item.alumni.alumni.map((alum) => {
//         return (
//             <Fade in key={alum._id}>
//                 <div className="">
//                     <SampleBlog alum={alum} />
//                 </div>
//             </Fade>
//         );
//     });

//     if (item.alumni.isLoading) {
//         return(
//                 <Loading />
//         );
//     }
//     else if (item.alumni.errMess) {
//         return(
//             <div className="col-12"> 
//                 <h4>{item.alumni.errMess}</h4>
//             </div>
//         );
//     }
//     else {
//         return (
//             <Media list>
//                 <Stagger in>
//                     {alumni}
//                 </Stagger>
//             </Media>
//         );
//     }
// }

// eslint-disable-next-line
function RenderCard({item, isLoading, errMess}) {

    if (isLoading) {
        return(
                <Loading />
        );
    }
    else if (errMess) {
        return(
                <h4>{errMess}</h4>
        );
    }
    else 
        return(
        <ScrollAnimation animateIn='fadeIn' delay={300}>
            <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
            <Card body className="blog-card text-center">
                <CardTitle tag="h5">
                    Some words
                </CardTitle>
                {/* <CardImg top width="50%" src={item.image} alt={item.name} /> */}
                {/* <CardBody>
                <CardTitle tag="h5">{item.name}</CardTitle>
                {item.designation ? <CardSubtitle>{item.designation}</CardSubtitle> : null }
                <CardText>{item.description.slice(0, 100) + "..."}</CardText>
                </CardBody> */}
            </Card>
            </FadeTransform>
            </ScrollAnimation>
        );
}

// Alumni Section

function RenderAlumni( {alum} ) {
    if (!alum.featured) {
        return(
            <div className="p-2">
            <div className="container">
            <div data-aos="fade-up-right" data-aos-duration="1200">
            {/* <div data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine"> */}
                <div className="d-flex flex-md-row flex-column homepage-alumnilist">
                    <div className="col-12 col-md-4 m-auto">
                        <img src={alum.image} alt={alum.name} height="auto" width="80%"></img>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2 border="1px solid black"><strong>{alum.name}</strong> <span className="small">{alum.work}</span></h2>
                        <br />
                        <p dangerouslySetInnerHTML={{__html: alum.desc}}></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        );
    }
    else{
        return(
            <div className="p-2">
            {/* <div className="container">                    
                <div className="d-flex flex-md-row-reverse flex-column bd">
                    <div className="col-12 col-md-4 m-auto">
                        <img src={alum.image} alt="" height="auto" width="80%"></img>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2 border="1px solid black"><strong>{alum.name}</strong> <span className="small">{alum.work}</span></h2>
                        <br />
                        <p dangerouslySetInnerHTML={{__html: alum.desc}}></p>
                    </div>
                </div>
            </div> */}
            </div>
        );
    }
    
        /* <div className="d-flex flex-md-row-reverse flex-column ">
            <div className="col-12 col-md-4 m-auto">
                <img src="../assets/images/logoWhiteBg.png" alt="" height="auto" width="100%"></img>
            </div>
            <div className="col-12 col-md-8">
                <h2 border="1px solid black">Some text</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div> */
    
}

function AlumList(props) {

    const alumni = props.alumni.alumni.map((alum) => {
        return (
            <Fade in key={alum._id}>
                <div className="">
                    <RenderAlumni alum={alum} />
                </div>
            </Fade>
        );
    });

    if (props.alumni.isLoading) {
        return(
                <Loading />
        );
    }
    else if (props.alumni.errMess) {
        return(
            <div className="col-12"> 
                <h4>{props.alumni.errMess}</h4>
            </div>
        );
    }
    else {
        return (
            <Media list>
                <Stagger in>
                    {alumni}
                </Stagger>
            </Media>
        );
    }
}

// 


// eslint-disable-next-line
function ShortDesc(){
    return(
        <ScrollAnimation animateIn='fadeIn' delay={300}>
        <div className="row short-desc">
            <div className="col-12 col-md-8">
                <h2 border="1px solid black">Ma'ashiyaat Economics Network</h2>
                <p>Ma'ashiyaat Economics Network was born out of an idea, a vision for society. A concept that establishes what we do and how we do that while daring to challenge contemporary principles. Ma'ashiyaat aims at rendering a platform for similar minds to come together to explore and solve the problems of the real world.</p>
            </div>
            <div className="col-12 col-md-4 m-auto">
                <img src="https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FOthers%2Fvidgif.gif?alt=media&token=87fb63cf-0db2-4b66-9296-095b37d1ec73" alt="" height="70%" width="70%"></img>
            </div>
        </div>
        </ScrollAnimation>
    );
}

// eslint-disable-next-line
function RenderDetails(){
        return(
            <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(2) translateY(-50%)'
                }}>

            <Card body className="blog-card text-center det-content m-auto">
                <CardImg top width="100%" src="https://firebasestorage.googleapis.com/v0/b/mshtserver.appspot.com/o/images%2Fmarketing%20team%20logo.png?alt=media&token=71113a8c-8ded-47ec-a12f-98fbbf8d7103" alt="Card image cap" />
            <CardBody>
                <CardTitle tag="h5">Card Title</CardTitle>
                <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
                <CardText>
                    <small className="text-muted">Last updated 3 mins ago</small>
                </CardText>
            </CardBody>
            </Card>
            <Card body inverse color="primary">
                <CardTitle tag="h5">Special Title Treatment</CardTitle>
            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                <Button color="secondary">Button</Button>
            </Card>
            </FadeTransform>
        );
}

function Home(props) {
    return(
        <div className="home-bg">
            <Helmet>
              <title>Home</title>
              <meta
                  name="description"
                  content="Research in Economics, Economics Articles, Ma'ashiyaat Economics Network was born out of an idea, a vision for society. A concept that establishes what we do and how we do that while daring to challenge contemporary principles. Ma'ashiyaat aims at rendering a platform for similar minds to come together to explore and solve the problems of the real world.
                  Ma'ashiyaat aims to promote new strands in economics pluralism. Because we have observed, the way economics is learnt and taught doesn't address adequately the challenges that the world faces."
                />
            </Helmet>
            <CarouselComp />
                <br />
            <Type />
                <br />
            <div className='container'>
                {/* <ShortDesc /> */}
            </div>

            <div className="container" border="solid 2px black">
                <br />
                {/* <ScrollAnimation animateIn='fadeIn' delay={300}>
                        <h1 className="text-center headng"> About Us</h1>
                </ScrollAnimation> */}
                {/* <ScrollAnimation animateIn='fadeIn' delay={300}> */}
                <div data-aos="flip-up" data-aos-duration="1100">
                <div className="about-div-main">
                    <div className="about-div">
                        <svg className="footerPart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
                            <path fill="#54eb9d" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,240C560,224,640,160,720,144C800,128,880,160,960,149.3C1040,139,1120,85,1200,96C1280,107,1360,181,1400,218.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
                        </svg>
                    </div>
                <div className="about-div-content">  
                <h1 className='headng'>About Us</h1>
                {/* <br />   */}
                    <p>Ma'ashiyaat Economics Network was born out of an idea, a vision for society. A concept that establishes what we do and how we do that while daring to challenge contemporary principles. Ma'ashiyaat aims at rendering a platform for similar minds to come together to explore and solve the problems of the real world.</p>
                    <p>Ma'ashiyaat aims to promote new strands in economics pluralism. Because we have observed, the way economics is learnt and taught doesn't address adequately the challenges that the world faces. </p>
                </div>
                    <div className="about-div">
                        <svg className="footerPart2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
                            <path fill="#54eb9d" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,240C560,224,640,160,720,144C800,128,880,160,960,149.3C1040,139,1120,85,1200,96C1280,107,1360,181,1400,218.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
                        </svg>
                    </div>
                </div>
                </div>
            {/* </ScrollAnimation> */}
                    {/* <ShortDesc /> */}

                    {/* <Slide blogs={props.blogs} /> */}
                <br />
                    <FeaturedBlogs blogs={props.blogs}/>
                <br />
                    {/* <ShortDesc /> */}
                 {/* <div className="col-12 col-md-6 m-auto">
                    <RenderCard item={props.blog} 
                    isLoading={props.blogsLoading}
                    errMess={props.blogsErrMess} />
                </div> */}
                <br />

                <div className='home-page-alumni-div'>
                    <h1 className='alumni-heading' style={{ 'text-align': 'center' }}>
                        Meet Our Advisors
                    </h1>
                    <AlumList alumni={props.alumni}/>
                </div>
                <br />
                {/* <div className="col-12 col-md-6 m-auto">
                    <RenderCard item={props.promotion}
                    isLoading={props.promosLoading}
                    errMess={props.promosErrMess} />
                </div>
                <br />
                <div className="col-12 col-md-6 m-auto">
                    <RenderCard item={props.leader}
                    isLoading={props.leaderLoading} 
                    errMess={props.leaderErrMess} />
                </div>
                <br />
                <div className="det">
                    <RenderDetails />
                </div> */} 
            </div>
            {/* <div className="col-12 carousel">
                    <Promo blogs={props.blogs} />
            </div> */}
          </div>
    );
}

export default Home;