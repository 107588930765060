export const ADD_COMMENT = 'ADD_COMMENT';
export const BLOGS_LOADING = 'BLOGS_LOADING';
export const BLOGS_FAILED = 'BLOGS_FAILED';
export const ADD_BLOGS = 'ADD_BLOGS';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const COMMENTS_FAILED = 'COMMENTS_FAILED';
export const PROMOS_LOADING = 'PROMOS_LOADING';
export const ADD_PROMOS = 'ADD_PROMOS';
export const PROMOS_FAILED = 'PROMOS_FAILED';
export const LEADERS_LOADING = 'LEADERS_LOADING';
export const ADD_LEADERS = 'ADD_LEADERS';
export const LEADERS_FAILED = 'LEADERS_FAILED';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const ALUMNI_LOADING = 'ALUMNI_LOADING';
export const ADD_ALUMNI = 'ADD_ALUMNI';
export const ALUMNI_FAILED = 'ALUMNI_FAILED';
export const POSTS_LOADING = 'POSTS_LOADING';
export const ADD_POSTS = 'ADD_POSTS';
export const POSTS_FAILED = 'POSTS_FAILED';