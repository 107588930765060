import React, { Component } from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, Breadcrumb, BreadcrumbItem, Col, Button, Modal, ModalBody, ModalHeader, Label, Row  } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Loading } from './LoadingComponent';
import ScrollAnimation from "react-animate-on-scroll";
import { FadeTransform, Fade, Stagger } from "react-animation-components";
import { Helmet } from 'react-helmet';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
// const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class CommentForm extends Component {

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isNavOpen: false,
      isModalOpen: false
    };
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  handleSubmit(values) {
    this.toggleModal();
    this.props.postComment(this.props.blogId, values.rating, values.author, values.email, values.comment); 
}

  render() {
    return (
      <React.Fragment>
        <Button className="comment-btn" onClick={this.toggleModal}><span className="fa fa-pencil fa-lg"></span> Add Comment</Button>
        <Modal className="mods" isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal} className="comment-modal-header">
          Add Comment
          </ModalHeader>
          <ModalBody>
            <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
              <Row className="form-group">
                <Label htmlFor="name" md={12}>Your Name</Label>
                <Col md={12}>
                {/* eslint-disable-next-line */}
                  <Control.text model=".author" id="author" className="form-control inpt" name="name" placeholder="Your Name *"
                                validators={{ required, minLength: minLength(3), maxLength: maxLength(15) }}/>
                  <Errors className="text-danger" model=".author" show="touched"
                          messages={{
                            
                            minLength: 'Must be greater than 2 characters. ',
                            maxLength: 'Must be 15 character or less. '
                          }} />
                </Col>
              </Row>
              {/* <Row className="form-group">
                  <Label htmlFor="mail" md={12}>Email</Label>
                  <Col md={12}>
                    //eslint-disable-next-line
                      <Control.text model=".email" id="email" placeholder="Email *" className="form-control inpt" name="mail"
                          validators={{
                              required, validEmail
                          }}
                            />
                      <Errors className="text-danger" model=".email" show="touched"
                          messages={{
                              required: 'Required. ',
                              validEmail: 'Invalid Email Address'
                          }}
                        />
                  </Col>
              </Row> */}
              <Row className="form-group">
                <Label htmlFor="comment" md={12}>Comment</Label>
                <Col md={12}>
                  {/* eslint-disable-next-line */}
                  <Control.textarea model=".comment" id="comment" rows="6" className="form-control inptMssg" name="message" />
                </Col>
              </Row>
              <Row className="form-group">
                <Col>
                  <Button type="submit" className="button" color="bg-primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </LocalForm>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function RenderBlog({blog}) {
    if (blog != null) {
        return(
          //<div className="col-12 col-md-5 m-1">
          
          <FadeTransform
                in
                transformProps={{
                    exitTransform: 'scale(0.5) translateY(-50%)'
                }}>
            
            <Card className="blog-page">
            <Helmet>
              <title>{blog.Title}</title>
              <meta
                  name="description"
                  content={blog.description}
                />
            </Helmet>
              
                <CardImg className="blog-img" top  height="auto" src={blog.image} alt={blog.name} />
                <CardBody>
                    <CardTitle className="blog-title text-left">
                      {blog.Title}
                      <br />
                      {/* <p className="blog-shortdesc">{blog.shortDesc}</p> */}
                      </CardTitle>
                    <CardText className="blog-desc"> 
                      <ScrollAnimation animateIn='fadeIn'>
                        <div className="blog-det" dangerouslySetInnerHTML={{__html: blog.description}}></div>
                        <p><strong>Written by: </strong>{blog.author}</p>
                        <p><strong>Edited by: </strong>{blog.editor}</p>
                      </ScrollAnimation>
                    </CardText>
                </CardBody>
            </Card>
            </FadeTransform>
            //</div>
        );
    }
    else {
        return(
            <div></div>
        )
    }
}

function RenderComments({comments, postComment, blogId}) {
  
  if (comments != null)
  return(
      <div className="col-12 col-md-11 m-1">
          <h3 className="comments-header">Comments</h3>
          <ul className="list-unstyled">
              <Stagger in>
                  {comments.map((comment) => {
                      return (
                        <ScrollAnimation animateIn='fadeIn'>
                          <Fade in key={comment._id}>
                            <li>
                             <div className="col-12 col-md-12 row">
                              <div className="col-1 col-md-1">
                                <img className="avatar" src="../assets/images/avatar.jpg" height="110px" width="95px" alt="avatar" />
                                <br />
                              </div>          
                              <div className="col-11 col-md-10 ml-auto">
                                  <h4>{comment.author} </h4>
                                  <small className="text-muted">on {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day:'2-digit'}).format(new Date(Date.parse(comment.updatedAt.toDate())))}</small>
                                  <p className="comment-mssg">{comment.comment}</p>
                              </div>
                            </div>
                              {/* <p>{comment.rating} stars</p> */}
                              </li>
                          </Fade>   
                        </ScrollAnimation>             
                      );
                  })}
              </Stagger>
          </ul>
          <CommentForm blogId={blogId} postComment={postComment} />
      </div>
  );
  else{
  return(
      <div></div>
  );
  }
  }
    // if (comments != null) 
    //   return (
    //     <div>
    //       <h4>Comments</h4>
    //       <ul className="list-unstyled">
    //         <Stagger in>
    //         { comments.map((comment) => {
    //     return (
    //       <Fade in key={comment._id}>
    //       <li>
    //         <p>{comment.comment}</p>
    //         <p>-- {comment.author}, {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(Date.parse(comment.date)))} </p>
    //       </li>
    //       </Fade>
    //     );
    //     })}
    //         </Stagger>
    //       </ul>
    //         <CommentForm blogId={blogId} postComment={postComment} />
    //     </div>
    //   );
    //   else
    //       return(
    //         <div></div>
    //     );

  const BlogDetail = (props) => {
    if(props.isLoading){
      return(
        <div className="container">
          <div className="row">
            <Loading />
          </div>
        </div>
      );
    }
    else if (props.errMess) {
      return(
          <div className="container">
              <div className="row">            
                  <h4>{props.errMess}</h4>
              </div>
          </div>
      );
    }
    else if (props.blog != null) 
      if (props.blog.isLoading) {
        return(
          <div className="container">
            <div className="row">
              <Loading />
            </div>
          </div>
        );
      }
      else
            return (
                <div className="container">
                    <div className="row">
                        <Breadcrumb className="breadCrumb">
                            <BreadcrumbItem><Link to='/blog'>Research</Link></BreadcrumbItem>
                            <BreadcrumbItem active>{props.blog.Title}</BreadcrumbItem>
                        </Breadcrumb>
                        <div className="col-12">
                            <h3>{props.blog.name}</h3>
                            <p className="author-name ml-2">By {props.blog.author}</p>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <RenderBlog blog={props.blog} />
                        <RenderComments comments={props.comments}
                            postComment={props.postComment}
                            blogId={props.blog._id} />
                    </div>
                </div>
            );
    // else if (props.blog != null) {
    //   return (
    //     <div className="container">
    //         <div className="row">
    //             <Breadcrumb>
    //                 <BreadcrumbItem><Link to='/menu'>Blogs</Link></BreadcrumbItem>
    //                 <BreadcrumbItem active>{props.blog.name}</BreadcrumbItem>
    //             </Breadcrumb>
    //             <div className="col-12">
    //                 <h3>{props.blog.name}</h3>
    //                 <hr />
    //             </div>
    //         </div>
    //     <div className="row">
    //       <div className="row">
    //       <div className="col-12 col-md-12 m-1">
    //         <RenderBlog blog={props.blog} />
          
    //       <div className="row">
    //       <div className="col-12 col-md-12 m-1">
    //         <RenderComments comments={props.comments}
    //         postComment={props.postComment}
    //         blogId={props.blog._id} />
    //       </div>
    //     </div>
    //   );
    else 
      return(
        <div>
          <h1>No content here</h1>
          </div>
      );
  }

export default BlogDetail;