import { config } from './config';
import firebase from 'firebase';
import "firebase/analytics";

firebase.initializeApp(config);

export const auth = firebase.auth();

export const fireauth = firebase.auth;

const settings = {timestampsInSnapshots: true};
firebase.firestore().settings(settings);
export const firestore = firebase.firestore();
// eslint-disable-next-line
const analytics = firebase.analytics();

firebase.analytics().logEvent('notification_received');

export const firebasestore = firebase.firestore;