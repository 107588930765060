import {createStore, combineReducers, applyMiddleware } from 'redux';
import { createForms } from 'react-redux-form';
import { Blogs } from './blogs';
import { Comments } from './comments';
import { Promotions } from './promotions';
import { Leaders } from './leaders';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { InitialFeedback } from './forms';
import { Posts } from './posts';
import { Alumni } from './alumni';
import { Auth } from './auth';

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            blogs: Blogs,
            comments: Comments,
            promotions: Promotions,
            leaders: Leaders,
            auth: Auth,
            alumni: Alumni,
            posts: Posts,
            ...createForms({
                feedback: InitialFeedback
            })
        }),
        applyMiddleware(thunk)
    );

    return store;
}