import * as ActionTypes from './ActionTypes';
import { auth, firestore, fireauth,  firebasestore } from '../firebase/firebase';

export const addComment = (comment) => ({
    type: ActionTypes.ADD_COMMENT,
    payload: comment
});

export const postComment = (blogId,  rating, author, email, comment) => (dispatch) => {

    if (!auth.currentUser) {
        console.log('No user logged in!');
        alert('No user logged in!');
        return;
    }

    return firestore.collection('comments').add({
        author: author,
        blog: blogId,
        id: auth.currentUser.uid,
        mailId: auth.currentUser.email,
        // emailId: email,
        comment: comment,
        createdAt: firebasestore.FieldValue.serverTimestamp(),
        updatedAt: firebasestore.FieldValue.serverTimestamp()
    })
    .then(docRef => {
        firestore.collection('comments').doc(docRef.id).get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    const _id = doc.id;
                    let comment = {_id, ...data};
                    dispatch(addComment(comment))
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            });
    })
    .catch(error => { console.log('Post comments ', error.message);
        alert('Your comment could not be posted\nError: '+ error.message); })
}

// Blogs stuff

export const fetchBlogs = () => (dispatch) => {
    dispatch(blogsLoading(true));

    return firestore.collection('Blogs').get()
        .then(snapshot => {
            let blogs = [];
            snapshot.forEach(doc => {
                const data = doc.data()
                const _id = doc.id
                blogs.push({_id, ...data });
            });
            return blogs;
        })
        .then(blogs => dispatch(addBlogs(blogs)))
        .catch(error => dispatch(blogsFailed(error.message)));
}

export const blogsLoading = () => ({
    type: ActionTypes.BLOGS_LOADING
});

export const blogsFailed = (errmess) => ({
    type: ActionTypes.BLOGS_FAILED,
    payload: errmess
});

export const addBlogs = (blogs) => ({
    type: ActionTypes.ADD_BLOGS,
    payload: blogs
});

export const fetchComments = () => (dispatch) => {
    return firestore.collection('comments').get()
        .then(snapshot => {
            let comments = [];
            snapshot.forEach(doc => {
                const data = doc.data()
                const _id = doc.id
                comments.push({_id, ...data });
            });
            return comments;
        })
        .then(comments => dispatch(addComments(comments)))
        .catch(error => dispatch(commentsFailed(error.message)));
}

export const commentsFailed = (errmess) => ({
    type: ActionTypes.COMMENTS_FAILED,
    payload: errmess
});

export const addComments = (comments) => ({
    type: ActionTypes.ADD_COMMENTS,
    payload: comments
});

export const fetchPromos = () => (dispatch) => {
    dispatch(promosLoading(true));

    return firestore.collection('promotions').get()
        .then(snapshot => {
            let promos = [];
            snapshot.forEach(doc => {
                const data = doc.data()
                const _id = doc.id
                promos.push({_id, ...data });
            });
            return promos;
        })
        .then(promos => dispatch(addPromos(promos)))
        .catch(error => dispatch(promosFailed(error.message)));
}

export const promosLoading = () => ({
    type: ActionTypes.PROMOS_LOADING
});

export const promosFailed = (errmess) => ({
    type: ActionTypes.PROMOS_FAILED,
    payload: errmess
});

export const addPromos = (promos) => ({
    type: ActionTypes.ADD_PROMOS,
    payload: promos
});

// Added lil bit later

export const fetchLeaders = () => (dispatch) => {
    
    dispatch(leadersLoading());

    return firestore.collection('teams').orderBy("count", "asc").get()
    .then(snapshot => {
        let leaders = [];
        snapshot.forEach(doc => {
            const data = doc.data()
            const _id = doc.id
            leaders.push({_id, ...data });
        });
        return leaders;
    })
    .then(leaders => dispatch(addLeaders(leaders)))
    .catch(error => dispatch(leadersFailed(error.message)));
}

export const leadersLoading = () => ({
    type: ActionTypes.LEADERS_LOADING
});

export const leadersFailed = (errmess) => ({
    type: ActionTypes.LEADERS_FAILED,
    payload: errmess
});

export const addLeaders = (leaders) => ({
    type: ActionTypes.ADD_LEADERS,
    payload: leaders
});

//Alumni section

export const fetchAlumni = () => (dispatch) => {
    
    dispatch(alumniLoading(true));

    return firestore.collection('alumni').get()
    .then(snapshot => {
        let alum = [];
        snapshot.forEach(doc => {
            const data = doc.data()
            const _id = doc.id
            alum.push({_id, ...data });
        });
        return alum;
    })
    .then(alum => dispatch(addAlumni(alum)))
    .catch(error => dispatch(alumniFailed(error.message)));
}

export const alumniLoading = () => ({
    type: ActionTypes.ALUMNI_LOADING
});

export const alumniFailed = (errmess) => ({
    type: ActionTypes.ALUMNI_FAILED,
    payload: errmess
});

export const addAlumni = (alum) => ({
    type: ActionTypes.ADD_ALUMNI,
    payload: alum
});

//Feedback

export const postFeedback = (feedback) => (dispatch) => {

    if (!auth.currentUser) {
        console.log('No user logged in!');
        alert('No user logged in!');
        return;
    }
        
    return firestore.collection('feedback').add( {submittedAt: firebasestore.FieldValue.serverTimestamp() ,feedback})
    .then(response => { console.log('Feedback', response); alert('Thank you for your feedback!'); })
    .catch(error =>  { console.log('Feedback', error.message); alert('Your feedback could not be posted\nError: '+error.message); });
};

export const requestLogin = () => {
    return {
        type: ActionTypes.LOGIN_REQUEST
    }
}

export const receiveLogin = (user) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        user
    }
}

export const loginError = (message) => {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        message
    }
}

export const loginUser = (creds) => (dispatch) => {
    dispatch(requestLogin(creds))

    return auth.signInWithEmailAndPassword(creds.username, creds.password)
    .then(() => {
        var user = auth.currentUser;
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(receiveLogin(user));
    })
    .catch(error => dispatch(loginError(error.message)))
};

export const requestLogout = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST
    }
}

export const receiveLogout = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS
    }
}

export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout())
    auth.signOut().then(() => {

    }).catch((error) => {

    });
    localStorage.removeItem('user');
    dispatch(receiveLogout())
}

export const googleLogin = () => (dispatch) => {
    const provider = new fireauth.GoogleAuthProvider();

    auth.signInWithPopup(provider)
    .then((result) => {
        var user = result.user;
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(receiveLogin(user));
    })
    .catch((error) => {
        dispatch(loginError(error.message));
    })
}