import React from 'react';
// import { Spinner } from 'reactstrap';
import { BallTriangle } from 'react-loader-spinner';


export const Loading = () => {
    return(
        <div className="col-12 spin">
            <BallTriangle
                heigth="100"
                width="100"
                color="black"
                ariaLabel="loading-indicator"
                />
            {/* <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="primary" />
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="secondary" />
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="success" />
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="danger" />
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" color="warning" /> */}
            {/* <span className="fa fa-circle-o-notch fa-spin fa-3x fa-fw spin center-text"></span> */}
            {/* <p className="spinner-text">Loading . . .</p> */}
        </div>
    );
};