import React, { Component } from 'react';
import Home from './HomeComponent';
import Menu from './BlogComponent';
import Contact from './ContactComponent';
import BlogDetail from './BlogDetailComponent';
import Alumni from './AlumniComponent';
import About from './AboutComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { postComment, fetchBlogs , fetchComments, fetchPromos, fetchLeaders, fetchAlumni, loginUser, logoutUser, googleLogin, postFeedback } from '../redux/ActionCreators';
import { actions } from 'react-redux-form';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const mapStateToProps = state => {
  return {
    blogs: state.blogs,
    comments: state.comments,
    promotions: state.promotions,
    posts: state.posts,
    leaders: state.leaders,
    alumni: state.alumni,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => ({
  postComment: (blogId, rating, author, email, comment) => dispatch(postComment(blogId, rating, author, email, comment)),
  fetchBlogs: () => {dispatch(fetchBlogs())},
  resetFeedbackForm: () => { dispatch(actions.reset('feedback'))},
  fetchComments: () => {dispatch(fetchComments())},
  fetchPromos: () => {dispatch(fetchPromos())},
  fetchLeaders: () => dispatch(fetchLeaders()),
  fetchAlumni: () => {dispatch(fetchAlumni())},
  postFeedback: (feedback) => dispatch(postFeedback(feedback)),
  loginUser: (creds) => dispatch(loginUser(creds)),
  logoutUser: () => dispatch(logoutUser()),
  googleLogin: () => dispatch(googleLogin())
});

class Main extends Component {

  componentDidMount() {

    this.props.fetchBlogs();
    this.props.fetchComments();
    this.props.fetchPromos();
    this.props.fetchLeaders();
    this.props.fetchAlumni();
  }

  componentWillUnmount() {
    this.props.logoutUser();
  }

  render() {

    // const HomePage = () => {
    //     return(
    //       <Home 
    //       blog={this.props.blogs.blogs.filter((blog) => blog.featured)}
    //       blogsLoading={this.props.blogs.isLoading}
    //       blogsErrMess={this.props.blogs.errMess}
    //       promotion={this.props.promotions.promotions.filter((promo) => promo.featured)[0]}
    //       promosLoading={this.props.promotions.isLoading}
    //       promosErrMess={this.props.promotions.errMess}
    //       leader={this.props.leaders.leaders.filter((leader) => leader.featured)[0]}
    //       leaderLoading={this.props.leaders.isLoading}
    //       leaderErrMess={this.props.leaders.errMess}
    //       />
    //     );
    // }

    const BlogWithId = ({match}) => {
      return(
        // (this.props.auth.isAuthenticated && this.props)
        <BlogDetail blog={this.props.blogs.blogs.filter((blog) => blog._id === match.params.blogId)[0]}
        isLoading={this.props.blogs.isLoading}
        errMess={this.props.blogs.errMess}
        comments={this.props.comments.comments.filter((comment) => comment.blog === match.params.blogId)}
        commentsErrMess={this.props.comments.errMess}
        postComment={this.props.postComment}
        />
      );
    };

    return (
      <div>
        <Header auth={this.props.auth} loginUser={this.props.loginUser} logoutUser={this.props.logoutUser} googleLogin={this.props.googleLogin} />
        <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
        <Switch>
              <Route path='/home' component={() => <Home blogs={this.props.blogs} alumni={this.props.alumni} /> } />
              <Route exact path="/aboutus" component={ () => <About leaders={this.props.leaders} /> } />
              <Route exact path='/research' component={() => <Menu blogs={this.props.blogs} /> } />
              <Route exact path='/alumni' component={() => <Alumni alumni={this.props.alumni} />} />
              <Route path="/research/:blogId" component={BlogWithId} />
              <Route exact path='/contactus' component={() => <Contact resetFeedbackForm={this.props.resetFeedbackForm} postFeedback={this.props.postFeedback} />} />
              <Redirect to="/home" />
        </Switch>
        </CSSTransition>
        </TransitionGroup>
        <Footer />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));