
import { Card,  CardBody, Breadcrumb, BreadcrumbItem, Collapse, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import { Component } from 'react';
import { Helmet } from 'react-helmet';


// eslint-disable-next-line
class Example extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
    // this.props = {blog : this.props}
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <div>
        <Button color="primary" onMouseOver={this.toggle} onMouseOut={this.toggle} style={{ marginBottom: '1rem' }}>Toggle</Button>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
                    {this.props.name}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}

// // import { Component } from 'react';
// // // import { Collapse, Button, CardBody, Card } from 'reactstrap';

// // class Example extends Component {
// //   constructor(props) {
// //     super(props);
// //     this.toggle = this.toggle.bind(this);
// //     this.state = { collapse: false };
// //   }

// //   toggle() {
// //     this.setState({ collapse: !this.state.collapse });
// //   }

// //   render() {
// //     return (
// //       <div>
// //         <Button color="primary"  style={{ marginBottom: '1rem' }}>Toggle</Button>
// //         <Collapse isOpen={this.state.collapse}>
// //           <Card>
// //             <CardBody>
// //             Anim pariatur cliche reprehenderit,
// //              enim eiusmod high life accusamus terry richardson ad squid. Nihil
// //              anim keffiyeh helvetica, craft beer labore wes anderson cred
// //              nesciunt sapiente ea proident.
// //             </CardBody>
// //           </Card>
// //         </Collapse>
// //       </div>
// //     );
// //   }
// // }

// function Example() {
//     const [open, setOpen] = useState(false);
  
//     return (
//       <>
//         <Button
//           onClick={() => setOpen(!open)}
//           aria-controls="example-collapse-text"
//           aria-expanded={open}
//         >
//           click
//         </Button>
//         <div style={{minHeight: '150px'}}>
//           <Collapse in={open} dimension="width">
//             <div id="example-collapse-text">
//               <Card body style={{width: '400px'}}>
//                   Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
//                   terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
//                   labore wes anderson cred nesciunt sapiente ea proident.
//               </Card>
//             </div>
//           </Collapse>
//         </div>
//       </>
//     );
//   }

// function Example() {
//     const [open, setOpen] = useState(false);
  
//     return (
//       <>
//         <Button
//           onMouseOver={() => setOpen(!open)}
//           aria-controls="example-collapse-text"
//           aria-expanded={open}
//         >
//           click
//         </Button>
//         <Collapse in={open}>
//           <div id="example-collapse-text">
//             Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
//             terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
//             labore wes anderson cred nesciunt sapiente ea proident.
//           </div>
//         </Collapse>
//       </>
//     );
//   }
  

function RenderMenuItem({ blog }) {
    // const [isOpen, setIsOpen] = React.useState(false);
    // const [isClose, setIsClose] = React.useState(true);

    return(
        <div>
        {/* <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
            <div className="sample-wrapper">
            <Card className="sample-card text-center" onMouseOver={() => { setIsOpen(isOpen)}} 
                onMouseOut={() => { setIsOpen(isOpen)}} >
                <Link to={`/blog/${blog._id}`} style={{ textDecoration: 'none' }} >
                    <CardImg className="card-image" width="100%" src={blog.image} alt={blog.name} />
                    <CardTitle className="details"><strong>{blog.Title}</strong></CardTitle>
                    <Collapse isOpen={isOpen}>
                            <small className="text-muted">{blog.authorName}</small>
                    </Collapse>
                </Link>            
            </Card>
            </div>
        </ScrollAnimation> */}
        {/* <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'> */}
        <div data-aos="zoom-in-right" data-aos-duration="1500">        
            <div className="sample-wrapper">
                <div className="sample-card">
                    <Link to={`/research/${blog._id}`} style={{ textDecoration: 'none' }} >
                        <div className="card-image">
                            <img width="100%" src={blog.image} alt={blog.name} />
                        </div>
                        <div className="details">
                            <h2><strong>{blog.Title} </strong></h2>
                        </div>
                    </Link>            
                </div>
            </div>
        {/* </ScrollAnimation> */}
        </div>
        </div>
    );
}

const Menu = (props) => {

    const menu = props.blogs.blogs.map((blog) => {
        return (
            <div key={blog._id} className="col-12 col-md-4 m-1">
               <RenderMenuItem blog={blog} />
            </div>
        );
    });


    if (props.blogs.isLoading) {
        return(
            <div className="container">
                <div className="row">            
                    <Loading />
                </div>
            </div>
        );
    }

    else if (props.blogs.errMess) {
        return(
            <div className="container">
                <div className="row"> 
                    <div className="col-12">
                        <h4>{props.blogs.errMess}</h4>
                    </div>
                </div>
            </div>
        );
    }
    
    else
        return (
            <div className="blog-page-bg">
            <Helmet>
                <title>Research</title>
                <meta
                    name="description"
                    content="Ma'ashiyaat Economics Network"
                    />
            </Helmet>
                <div className="blog-Jumbotron">
                    <div className="container">
                        <div className="row row-header">
                            <div className="col-12">
                                <br />
                                <h1 className='text-center'>Research</h1>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container">
                <div className="row">
                    <Breadcrumb className="breadCrumb">
                        <BreadcrumbItem><Link to='/home'>Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Research</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Research</h3>
                        <hr />
                    </div>
                </div>
                <div className="row">
                        {menu}
                </div>                
            </div>
         { /*  <div className="cnt">
                <section className="waveMain">
                    <div className="wave wave1">
                        <svg viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L48,90.7C96,117,192,171,288,170.7C384,171,480,117,576,112C672,107,768,149,864,154.7C960,160,1056,128,1152,106.7C1248,85,1344,75,1392,69.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    </div>
                    <div className="wave wave2">
                        <svg viewBox="0 0 1440 280"><path fill="#0099ff" fill-opacity="0.7" d="M0,192L48,176C96,160,192,128,288,138.7C384,149,480,203,576,213.3C672,224,768,192,864,170.7C960,149,1056,139,1152,122.7C1248,107,1344,85,1392,74.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    </div>
                    <div className="wave wave3">

                    </div>
                </section>
            </div> */}
            </div>
        );
}

export default Menu;