import * as ActionTypes from './ActionTypes';

export const Alumni = (state  = { isLoading: true,
                                    errMess: null,
                                    alumni:[]}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ALUMNI:
        return {...state, isLoading: false, errMess: null, alumni: action.payload};

        case ActionTypes.ALUMNI_LOADING:
            return {...state, isLoading: true, errMess: null, alumni: []}

        case ActionTypes.ALUMNI_FAILED:
            return {...state, isLoading: false, errMess: action.payload};

        default:
          return state;
    }
};
