import React, { useState } from 'react';
 // eslint-disable-next-line
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import {Fade} from 'react-animation-components';
import { Link } from 'react-router-dom'; 
const items = [
  {
    
    src: 'https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FLogo%20Files%2Fcarousel-bg.png?alt=media&token=36742466-52f9-4567-acfa-bc9150448d1d',
    altText: 'Slide 1',
    caption: "Ma'ashiyaat Economics Network",
    moto: 'Reimagining and Reinventing approach towards Economics',
    visibl: 'd-none'
  },
  {
    
    src: 'https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FOthers%2Fwebsite%20collage.png?alt=media&token=69bb13d1-7460-4133-bbfd-403d0fdf8ca1',
    altText: 'Slide 2',
    caption: '',
    moto: '',
    visibl: 'visible',
    buttonText: 'Read latest Research'

  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FOthers%2Fvidgif.gif?alt=media&token=87fb63cf-0db2-4b66-9296-095b37d1ec73",
    altText: 'Slide 3',
    caption: '',
    visibl: 'd-none',
    moto: ''
  }
];

const CarouselComp = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="carousels"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="carouselImage" />
        {/* <img src='https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fwavesss.svg?alt=media&token=73c7c159-ed2b-401b-93c2-5a9d6dcee9bb' alt='bg' width="100%" height="auto" className="carouselImage2" /> */}

        {/* <img src='https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Fwavess.png?alt=media&token=296844aa-befe-4bf9-9552-7909cd003217' alt='bg' width="100%" height="600px" className="carouselImage2" /> */}

        {/* <img src='https://firebasestorage.googleapis.com/v0/b/mshtt-server.appspot.com/o/images%2Flayered-waves-haikei%20(5).png?alt=media&token=a4010b73-2fa0-4133-a6ca-1154db34daeb' alt='bg' width="100%" height="600px" className="carouselImage2" /> */}
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        {/* <ScrollAnimation animateIn='bounceInRight'> */}
        {/* <CarouselCaption captionHeader={item.caption}/> */}
        <Fade
            in transitionDelay={5000}
        >
          <div className="carousel-text-div">

            <h1 className='carousel-captions'>{item.caption}</h1>
            
            <p className='carousel-text'>{item.moto}</p>

            <div className={item.visibl}><Link  to='/research'><div className='carousels-btn' style={{ color: 'whitesmoke' }}>{item.buttonText}</div></Link></div>

            {/* <div id='if-part' style='visibility: hidden;'>do something</div>
            <div id='else-part' style='visibility: hidden'>do something</div>

            <script>
              var node;
              if({item.buttonText} == null){
                 node = document.getElementById('if-part')
              }
              else{
                  node = document.getElementById('else-part')
              }
              node.style.visibility = 'visible';
            </script> */}

            {/* <script>
            if ({item.buttonText} == null){ 
                <div className='button carousel-btn'><Link style={{ color: '#5cb6f9' }} to='/research'>{item.buttonText}</Link></div>
                }
            else{
                <div>ppp</div>
            }

            </script> */}
          
          </div>
        </Fade>
        {/* <b><CarouselCaption  /></b> */}

        {/* </ScrollAnimation> */}
        
      </CarouselItem>
    );
  });

  return (
    <Carousel className="carousel-fade"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      
      <CarouselControl className="carousel-control" direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl className="carousel-control" direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default CarouselComp;