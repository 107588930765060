import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from "./ScrollToTopComponent";
import { BsInstagram } from "react-icons/bs";
import { FiTwitter, FiFacebook, FiMail } from "react-icons/fi";
import { AiOutlineLinkedin } from "react-icons/ai";



function Footer(props) {
    return(

    <div className="footer">
        <div className="footer-div">                

            <svg className="footerPart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
                <path fill="#051d2e" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,240C560,224,640,160,720,144C800,128,880,160,960,149.3C1040,139,1120,85,1200,96C1280,107,1360,181,1400,218.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
            </svg>
            
        </div>
        <div className="container">
        <div data-aos="zoom-in-up" data-aos-duration="1000">
            <div className="row justify-content-center">             
                <div className="col-4 col-sm-2 ">
                    <h5>Links</h5>
                    <ul className="list-unstyled footer-link">
                        <ScrollToTop />
                        <li><Link style={{ color: '#5cb6f9' }} to='/home'>Home</Link></li>
                        <li><Link style={{ color: '#5cb6f9' }} to='/aboutus'>About Us</Link></li>
                        <li><Link style={{ color: '#5cb6f9' }} to='/research'>Research</Link></li>
                        <li><Link style={{ color: '#5cb6f9' }} to='/alumni'>Alumni Network</Link></li>
                        <li><Link style={{ color: '#5cb6f9' }} to='/contactus'>Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col-7 col-sm-5">
                <h5>Our Address</h5>
                    <address>
                    Maulana Mohammad Ali Jauhar Marg,<br />
                    Jamia Nagar, New Delhi-110025<br />
                    INDIA<br />
                    <i className="fa fa-phone"></i>: +91 9711004021<br />
                    <i></i> <a style={{ color: '#5cb6f9' }} href="mailto:Maashiyaatjmi@gmail.com">Maashiyaatjmi@gmail.com</a>
                    </address>
                </div>
                <div className="col-12 col-sm-4 align-self-center">
                    <div className="text-center">
                        <h4><a href="# ">Back to top</a></h4>
                        <br />
                        <a className="btn btn-i btn-social-icon btn-facebook-o" href="https://facebook.com/maashiyaat"><FiFacebook /></a>
                        <a className="btn btn-i btn-social-icon btn-linkedin-o" href="https://in.linkedin.com/company/ma-ashiyaat-econetwork"><AiOutlineLinkedin /></a>
                        <a className="btn btn-i btn-social-icon btn-twitter-o" href="https://twitter.com/maashiyaat"><FiTwitter /></a>
                        <a className="btn btn-i btn-social-icon btn-instagram-o" href="https://Instagram.com/maashiyaat_"><BsInstagram /></a>
                        {/* <a className="btn btn-social-icon btn-youtube" href="http://youtube.com/"><i className="fa fa-youtube"></i></a> */}
                        <a className="btn btn-i btn-social-icon btn-mail-o" href="mailto:Maashiyaatjmi@gmail.com"><FiMail /></a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">             
                <div className="col-auto">
                    <p>© Copyright 2022 Ma'ashiyaat Eco Network</p>
                    <p className="text-center">Made with <span className="emoji">&hearts;</span> by <a rel="noreferrer" href="https://www.linkedin.com/in/asad-imtiyaz-70a2211b7" target="_blank" style={{ color: '#5cb6f9' }}>Asad Imtiyaz</a></p>
                </div>
            </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;