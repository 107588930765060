import React, { Component } from 'react';
// eslint-disable-next-line
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, ModalHeader, Button, Modal, ModalBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ScrollToTop  from "./ScrollToTopComponent";

// window.onscroll = function() {scrollFunction()};

// function scrollFunction() {
//   if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
//     document.getElementById("navbar").style.padding = "30px 10px";
//     document.getElementById("logo").style.fontSize = "25px";
//   } else {
//     document.getElementById("navbar").style.padding = "80px 10px";
//     document.getElementById("logo").style.fontSize = "35px";
//   }
// }

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false,
            isModalOpen: false
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        }

        toggleNav() {
            this.setState({
                isNavOpen: !this.state.isNavOpen
            });
        }

        toggleModal() {
            this.setState({
                isModalOpen: !this.state.isModalOpen
            });
        }

        handleLogin(event) {
            this.toggleModal();
            this.props.loginUser({username: this.username.value, password: this.password.value});
            event.preventDefault();
        }

        handleGoogleLogin(event){
            this.toggleModal();
            this.props.googleLogin();
            event.preventDefault();
        }

        handleLogout() {
            this.props.logoutUser();
        }

    render() {
        return(
            <React.Fragment>
            <div>
                <Navbar dark expand="md" className="fixed-top" id="navbar">
                    {/* <div className="container"> */}
                        <ScrollToTop />
                        <NavbarToggler onClick={this.toggleNav} />
                        <NavbarBrand className="mr-auto" href="/">
                            <img src='https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FLogo%20Files%2FlogoAndNameWhiteBg1.png?alt=media&token=5f0e0141-75d1-434b-be92-0f5dc11a70b3' height="46px" alt="Ma'ashiyaat Logo" /></NavbarBrand>
                        <Collapse isOpen={this.state.isNavOpen} navbar>
                            <Nav navbar className="ml-auto">
                            <NavItem>
                                <NavLink className="nav-link"  to='/home'>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to='/aboutus'>About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link"  to='/research'>Research</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link"  to='/alumni'>Alumni Network</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link" to='/contactus'>Contact Us</NavLink>
                            </NavItem>
                            </Nav>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                { !this.props.auth.isAuthenticated ?
                                    <Button outline onClick={this.toggleModal}>
                                        <span className='fa fa-sign-in fa-lg'></span> login
                                        {this.props.auth.isFetching ?
                                            <span className='fa fa-spinner fa-pulse fa-fw'></span>
                                        : null 
                                        }
                                    </Button>
                                    :
                                    <div>
                                        <div className='navbar-text mr-3 user-name'><strong>{this.props.auth.user.displayName}</strong></div>
                                        <Button outline onClick={this.handleLogout}>
                                            <span className='fa fa-sign-out fa-lg'></span> Logout
                                            {this.props.auth.isFetching ?
                                                <span className='fa fa-spinner fa-pulse fa-fw'></span>
                                            : null
                                        }
                                        </Button>
                                    </div>
                            }
                            </NavItem>
                        </Nav>
                    </Collapse>
                    {/* </div> */}
                </Navbar>
                {/* <Jumbotron>
                    <div className="container">
                        <div className="row row-header">
                            <div className="col-12 col-sm-6">
                            </div>
                        </div>
                    </div>
                </Jumbotron> */}
            </div>
            <Modal className="modal-text" isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>Login</ModalHeader>
                <ModalBody>
                    {/* <Form onSubmit={this.handleLogin}>
                        <FormGroup>
                            <Label htmlFor="username">Email</Label>
                            <Input type="text" id="username" name="username" innerRef={(input) => this.username = input} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="password">Password</Label>
                            <Input type="password" id="password" name="password" innerRef={(input) => this.password = input} />
                        </FormGroup>
                        <FormGroup>
                            <Label check>
                            <Input type="checkbox"  name="remember" innerRef={(input) => this.remember = input} /> Remember me </Label>
                        </FormGroup>
                      <Button type="submit" value="submit" color="primary">Login</Button>
                    </Form> */}
                    <p></p>
                    <div className="text-center">
                        <Button className="button" color="white" onClick={this.handleGoogleLogin}><span className='fa fa-google fa-lg'></span> Login with Google</Button>
                    </div>
                </ModalBody>

            </Modal>
            </React.Fragment>
        );
    }
}

export default Header;