import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody,  Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loading } from './LoadingComponent';
import ScrollAnimation from 'react-animate-on-scroll';
import { Fade, Stagger } from 'react-animation-components';
import { Helmet } from "react-helmet";

function RenderLeader( {leader} ) {
    return (
        <div data-aos="zoom-in-right" data-aos-duration="1500">
        {/* //  <ScrollAnimation animateIn='fadeIn' delay={300}>
        //     <div className="d-flex flex-md-row flex-column teams">
        //         <div className="col-12 col-md-4"> */}
                <div className="property-card">
                    {/* <div className="sample-cards"> */}
                        <img className="property-image" src={leader.image} alt="" />
                        {/* </div> */}
                        {/* // <div className="col-12 col-md-6 ml-auto"> */}
                            <br />
                        <div className="property-description">
                            <h5 className=""><strong>{leader.name}</strong></h5>
                            <p>{leader.designation}</p>
                            <p className="">{leader.description}</p>
                        </div>
                    {/* </div> */}
                </div>
                {/* /*   </div>
            </div> */ }
        {/* //  </ScrollAnimation> */}
        </div>
    );
}

function LeaderList(props) {

    const leaders = props.leaders.leaders.map((leader) => {
        return (
            <Fade in key={leader._id}>
                <div className="">
                    <RenderLeader leader={leader} />
                </div>
            </Fade>
        );
    });

    if (props.leaders.isLoading) {
        return(
                <Loading />
        );
    }
    else if (props.leaders.errMess) {
        return(
            <div className="col-12"> 
                <h4>{props.leaders.errMess}</h4>
            </div>
        );
    }
    else {
        return (
            <Media list>
                 <Stagger in>
                    <div className="row">
                        {leaders}
                    </div>
                 </Stagger>
             </Media>
        );
    }
}
  
function About(props) {

    return(
        <div className="about-page-bg">
            <Helmet>
              <title>About Us</title>
              <meta
                  name="description"
                  content="Ma'ashiyaat Economics Network, Asad Imtiyaz, Abdullah Aftab, Gaurav, Hamza Ahmad, Samiran Saha, Shivangi"
                />
            </Helmet>
            <div className="about-Jumbotron">
                    <div className="container">
                        <div className="row row-header">
                            <div className="col-12">
                                <br />
                                    {/* <ScrollAnimation animateIn='zoom' delay={100}> */}
                                        <h1 className='text-center'>About Us</h1>
                                    {/* </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">    
            <div className="row">
                <Breadcrumb className="breadCrumb">
                    <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>About Us</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    {/* <h3>About Us</h3> */}
                    <hr />
                </div>                
            </div>
            <div className="row row-content about-us">
                <div className="col-12 col-md-6">
                    <ScrollAnimation animateIn='fadeIn' delay={300}>
                        <h1>Who we are</h1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeIn' delay={300}>
                        <p>Ma'ashiyaat Economics Network was born out of an idea, a vision for society. A concept that establishes what we do and how we do that while daring to challenge contemporary principles. Ma'ashiyaat aims at rendering a platform for similar minds to come together to explore and solve the problems of the real world.</p>
                        <p>Ma'ashiyaat aims to promote new strands in economics pluralism. Because we have observed, the way economics is learnt and taught doesn't address adequately the challenges that the world faces. </p>
                    </ScrollAnimation>

                </div>
                <div className="col-12 col-md-5">
                {/* <ScrollAnimation animateIn='fadeIn' delay={400}> */}
                    <Card>
                        <br />
                        <div data-aos="zoom-in-up">
                            <div className="card-image">
                                <img width="100%" height="220px" alt="" src="https://firebasestorage.googleapis.com/v0/b/ma-ashiyaat-server.appspot.com/o/images%2FLogo%20Files%2FlogoAndNameWhiteBg1.png?alt=media&token=5f0e0141-75d1-434b-be92-0f5dc11a70b3"></img>
                            </div>  
                        </div>                          
                    </Card>
                {/* </ScrollAnimation> */}
                </div>
                <div className="col-12">
                <ScrollAnimation animateIn='fadeIn' delay={400}>
                    {/* <br /> */}
                    <p>Traditionally, Economic institutions have failed to predict crises. In rare cases that they did, their actions seldom were grounded in reality . In recent history, we saw the world economy collapse. Why? Because US Federal Reserve was keeping interest rates super-low to boost investments, where did it lead? Massive corruption and overvalued mortgage banking. Resulting in the 2008 financial crash. The world economy crashed, banks closed shop and the debt market collapsed. Crises like this one gives us an opportunity to rethink policy making and help create better safety nets. </p>
                    <p>We hope to make Ma'ashiyaat a platform to express different ideas and address problems by rethinking policy making.</p>
                </ScrollAnimation>
                </div>
                <div className="col-12">
                <ScrollAnimation animateIn='fadeIn' delay={300}>
                    <Card>
                        <CardBody className="bg-faded">
                            <blockquote className="blockquote">
                                <p className="mb-0">Why does a public discussion of economic policy so often show the abysmal ignorance of the participants?</p>
                                <footer className="blockquote-footer">Robert Solow,
                                <cite title="Source Title"> Nobel Laureate</cite>
                                </footer>
                            </blockquote>
                        </CardBody>
                    </Card>
                </ScrollAnimation>
                </div>
            </div>
            {/* <div className="row row-content"> */}
                <div className="col-12 col-md-12">
                    <h2>Team Members</h2>
                    <br />
                </div>
                <div>
                    <LeaderList leaders={props.leaders} />
                </div>
            {/* </div> */}
        </div>
    </div>
    );
}

export default About;    