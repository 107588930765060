import React from "react";
import { Loading } from './LoadingComponent';
import { Link } from "react-router-dom";
import { Fade, Stagger } from 'react-animation-components';
import { Breadcrumb, BreadcrumbItem, Media } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

AOS.init();
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

function RenderAlumni( {alum} ) {
    if (alum.featured) {
        return(
            <div className="p-2">
            <div className="container">
            <div data-aos="fade-right" data-aos-duration="800">
                <div className="d-flex flex-md-row flex-column bd">
                    <div className="col-12 col-md-4 m-auto">
                        <img src={alum.image} alt="" height="auto" width="80%"></img>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2 border="1px solid black"><strong>{alum.name}</strong> <span className="small">{alum.work}</span></h2>
                        <br />
                        <p dangerouslySetInnerHTML={{__html: alum.desc}}></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        );
    }
    else{
        return(
            <div className="p-2">
            <div className="container"> 
            <div data-aos="fade-right" data-aos-duration="1000">                   
                <div className="d-flex flex-md-row-reverse flex-column bd">
                    <div className="col-12 col-md-4 m-auto">
                        <img src={alum.image} alt="" height="auto" width="80%"></img>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2 border="1px solid black"><strong>{alum.name}</strong> <span className="small">{alum.work}</span></h2>
                        <br />
                        <p dangerouslySetInnerHTML={{__html: alum.desc}}></p>
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
    
        /* <div className="d-flex flex-md-row-reverse flex-column ">
            <div className="col-12 col-md-4 m-auto">
                <img src="../assets/images/logoWhiteBg.png" alt="" height="auto" width="100%"></img>
            </div>
            <div className="col-12 col-md-8">
                <h2 border="1px solid black">Some text</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div> */
    
}

function AlumList(props) {

    const alumni = props.alumni.alumni.map((alum) => {
        return (
            <Fade in key={alum._id}>
                <div className="">
                    <RenderAlumni alum={alum} />
                </div>
            </Fade>
        );
    });

    if (props.alumni.isLoading) {
        return(
                <Loading />
        );
    }
    else if (props.alumni.errMess) {
        return(
            <div className="col-12"> 
                <h4>{props.alumni.errMess}</h4>
            </div>
        );
    }
    else {
        return (
            <Media list>
                <Stagger in>
                    {alumni}
                </Stagger>
            </Media>
        );
    }
}

function Alumni(props) {
    return(
        <div className="alumni-page-bg">
            <Helmet>
              <title>Alumni Network</title>
              <meta
                  name="description"
                  content="Alumni Network, Ma'ashiyaat Economics Network"
                />
            </Helmet>
            <div className="alumni-Jumbotron">
                    <div className="container">
                        <div className="row row-header">
                            <div className="col-12">
                                <br />
                                <h1 className='text-center'>Alumni Network</h1>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="container">
            <div className="row">
                <Breadcrumb className="breadCrumb">
                        <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Alumni Network</BreadcrumbItem>
                </Breadcrumb>
            </div>
            <div className="row row-content">
                <div className="col-12">
                    <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                         <h2>Alumni Network</h2>
                    </ScrollAnimation>
                    <br />
                </div>
                <AlumList alumni={props.alumni} />
            </div>
          </div>
        </div>
    )
    
}

export default Alumni;


// import React from 'react';
// import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Media } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import { Loading } from './LoadingComponent';
// import { Fade, Stagger } from 'react-animation-components';

// function RenderLeader( {leader} ) {
//     return (
//       <div className="d-flex flex-md-row flex-column">
//         <div className="col-12 col-md-4">
//          <img className="" width="80%" height="90%" src={leader.image} alt="" />
//         </div>
//          <div className="col-12 col-md-8 m-auto">
//             <h5 className="">{leader.name}</h5>
//             {/* <p>{leader.designation}</p> */}
//             {/* <p className="">{leader.description}</p> */}
//          </div>
//     </div>
//     );
// }

// function LeaderList(props) {

//     const alumni = props.alumni.alumni.map((leader) => {
//         return (
//             <Fade in key={leader._id}>
//                 <div className="">
//                     <RenderLeader leader={leader} />
//                 </div>
//             </Fade>
//         );
//     });

//     if (props.alumni.isLoading) {
//         return(
//                 <Loading />
//         );
//     }
//     else if (props.alumni.errMess) {
//         return(
//             <div className="col-12"> 
//                 <h4>{props.alumni.errMess}</h4>
//             </div>
//         );
//     }
//     else {
//         return (
//             <Media list>
//                 <Stagger in>
//                     {alumni}
//                 </Stagger>
//             </Media>
//         );
//     }
// }
  
// function Alumni(props) {

//     return(
//         <div className="container">
//             <div className="row">
//                 <Breadcrumb>
//                     <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
//                     <BreadcrumbItem active>About Us</BreadcrumbItem>
//                 </Breadcrumb>
//                 <div className="col-12">
//                     <h3>About Us</h3>
//                     <hr />
//                 </div>                
//             </div>
//             <div className="row row-content">
//                 <div className="col-12 col-md-6">
//                     <h2>Our History</h2>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
//                     {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
//                 </div>
//                 <div className="col-12 col-md-5">
//                     <Card>
//                         <CardHeader className="bg-primary text-white">Facts At a Glance</CardHeader>
//                         <CardBody>
//                             <dl className="row p-1">
//                                 <dt className="col-6">Started</dt>
//                                 <dd className="col-6">3 Feb. 2013</dd>
//                                 <dt className="col-6">Major Stake Holder</dt>
//                                 <dd className="col-6">HK Fine Foods Inc.</dd>
//                                 <dt className="col-6">Last Year's Turnover</dt>
//                                 <dd className="col-6">$1,250,375</dd>
//                                 <dt className="col-6">Employees</dt>
//                                 <dd className="col-6">40</dd>
//                             </dl>
//                         </CardBody>
//                     </Card>
//                 </div>
//                 <div className="col-12">
//                     <Card>
//                         <CardBody className="bg-faded">
//                             <blockquote className="blockquote">
//                                 <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
//                                 <footer className="blockquote-footer">Yogi Berra,
//                                 <cite title="Source Title">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</cite>
//                                 </footer>
//                             </blockquote>
//                         </CardBody>
//                     </Card>
//                 </div>
//             </div>
//             <div className="row row-content">
//                 <div className="col-12 col-md-12">
//                     <h2>Corporate Leadership</h2>
//                 </div>
//                 <LeaderList alumni={props.alumni} />
//             </div>
//         </div>
//     );
// }

// export default Alumni;